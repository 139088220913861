<template>
  <layout-full>
    <router-view />
  </layout-full>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'

export default {
  components: {
    LayoutFull,
  },
}
</script>

<style lang="scss">
  .mio_btn_float{
    display: block;
    position: absolute;
    top: -55px;
    right: 2px;
    z-index: 10;
  }
</style>